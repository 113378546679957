<template>
  <div class="config-wrap">
    <van-nav-bar title="我的"  fixed :placeholder="true" />
    <div>
      <van-cell title="登陆名" :value="userInfo.nickName" />
      <van-cell title="登陆手机" :value="userInfo.mobile" />
      <van-cell is-link title="影院开放规则" @click="ruleDetail"></van-cell>
    </div>
    <div style="padding: 40px 10px;">
      <van-button round type="danger" style="width: 100%;" @click="beforeLogout">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      show:false
    };
  },
  computed: {
    ...mapGetters(['hasGetUserInfo']),
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    ...mapActions(['logout']),
    beforeLogout() {
      this.$dialog
        .confirm({
          title: '系统提示',
          message: '确认退出登录吗?',
        })
        .then(() => {
          this.logout();
        });
    },
    ruleDetail(){
      this.$router.push("/cinema/rule")
    }
  },
};
</script>
<style scoped lang="scss">
.config-wrap {

}
</style>
